import { styled } from "@stitches/react";

import { Hand } from "../../ui/room/Hand";
import { Spread } from "../../ui/room/Spread";
import { RoomState, SpreadText, freeDeckIds } from "../../types/room/types";
import { localState } from "~/state/state";

import { Shuffle } from "./Shuffle";
import { AV } from "../../av/AV";
import { AnimatePresence, motion } from "framer-motion";
import { Perspective } from "../../ui/room/Perspective";
import {
  initialStorage,
  RoomProvider,
  useMutateStorage,
  useStorage,
} from "../../state/liveblocks.config";
import { ClientSideSuspense, shallow } from "@liveblocks/react";
import { ActionPortalTarget } from "../../ui/room/Actions";
import { Cursors } from "../../ui/room/Cursors";
import { ThemedApp } from "../../ui/style/ThemedApp";
import { useSnapshot } from "valtio";
import { BottomBar } from "~/ui/menus/BottomBar";
import { RoomMenus } from "~/ui/menus/RoomMenus";
import { useRequireAuth } from "~/utils/useRequireAuth";
import { LoadingPage } from "../loading/LoadingPage";
import { useEffect, useState } from "react";
import React from "react";
import { getRoom } from "~/api/roomApi";
import {
  MAX_ROOM_ATTENDEES,
  MOBILE_ZOOM_INDEX_MOD,
  PAN_BASE,
  ZOOM_BASE,
} from "~/utils/consts";
import { RoomFull } from "../404/RoomFull";
import { MobileMessage } from "~/ui/components/MobileMessage";
import roomImagesToPreload from "~/preloading/roomImagesToPreload.json";
import { ImagePreloader } from "~/preloading/Preloader";
import { SpreadTextOverlay } from "~/ui/room/SpreadText";
import { LiveList } from "@liveblocks/client";
import { Vec2d } from "~/utils/useMousePosition";
import { UserProfile } from "~/api/userApi";
import { allDecks } from "~/ui/menus/DeckMenu";

export const PERSPECTIVE_SHUFFLE = 1000;
export const ANGLE_SHUFFLE = 35;
export const PERSPECTIVE_DRAW = 1000;
export const ANGLE_DRAW = 26;

export const Room = React.memo(function Room({
  id,
  user,
}: {
  id: string;
  user?: UserProfile;
}) {
  const _auth = useRequireAuth();
  return (
    <RoomProvider
      id={id}
      initialPresence={{
        cursor: null,
        prevCursor: null,
        activeShape: null,
        color: "",
      }}
      initialStorage={initialStorage}
    >
      <ClientSideSuspense
        fallback={<LoadingPage connectionPage={true} noText={true} />}
      >
        {() => <RoomContents id={id} user={user} />}
      </ClientSideSuspense>
    </RoomProvider>
  );
});

const RoomContents = React.memo(function RoomContents({
  id,
  user,
}: {
  id: string;
  user?: UserProfile;
}) {
  const deck = useStorage((root) => root.deck, shallow);
  const background = useStorage((root) => root.background);
  const theme = useStorage((root) => root.theme);
  const reversesOn = useStorage((root) => root.reversesOn);
  const inRoomUsernames = useStorage((root) => root.usernames);
  const isCardSearchOpen = useStorage((root) => root.isCardSearchOpen);

  const [isAVLoaded, setIsAVLoaded] = useState(false);
  const [isRoomFull, setIsRoomFull] = useState(false);
  const [areImagesPreloaded, setAreImagesPreloaded] = useState(false);

  useEffect(() => {
    // add username to liveblocks list of usernames for the room
    if (!user?.username) return;
    updateStorage((storage) => {
      storage.set("usernames", [
        ...inRoomUsernames.filter((n) => n !== user?.username),
        user?.username,
      ]);
    });
  }, [user?.username]);

  useEffect(() => {
    // get current deck from query params
    const urlParams = new URLSearchParams(window.location.search);
    const deckName = urlParams.get("deck");
    const deck = allDecks.find((d) => d.name === deckName);
    if (
      deck &&
      (user?.decks.includes(deck.id) || freeDeckIds.includes(deck.id))
    ) {
      updateStorage((storage) => {
        storage.set("deck", deck);
      });
    }
  }, []);

  const updateStorage = useMutateStorage();
  const {
    inDrawingMode,
    inTypingMode,
    isGuidebookOpen,
    isInRoom,
    pan,
    isOnMobile,
    zoomIndex,
    isFocusedTyping,
    isOnPlusPlan,
  } = useSnapshot(localState);
  const zoomIndexMod = isOnMobile ? MOBILE_ZOOM_INDEX_MOD : 0;

  const panScreen = [pan[0] * PAN_BASE, pan[1] * PAN_BASE];
  const zoomVector = [
    ZOOM_BASE ** (zoomIndex + zoomIndexMod),
    ZOOM_BASE ** (zoomIndex + zoomIndexMod),
  ] as Vec2d;

  let isInDrawState = null;
  if (!isOnMobile)
    isInDrawState = useStorage(
      (root) => root.state === RoomState.Draw,
      shallow
    );

  useEffect(() => {
    if (isInRoom) localState.isInRoom = false;
    else {
      localState.isInRoom = true;
    }
  }, []);

  useEffect(() => {
    if (!id) return;
    (async () => {
      const room = await getRoom(id);
      if (!room) return;
      if (
        room.numParticipants &&
        room.numParticipants > MAX_ROOM_ATTENDEES - 1
      ) {
        setIsRoomFull(true);
      }
    })();
  }, [id]);

  useEffect(() => {
    localState.inDarkMode = theme === "dark" ? true : false;
    return () => {
      localState.inDarkMode = false;
    };
  }, [theme]);

  // track connection status app-wide
  useEffect(() => {
    if (isAVLoaded) {
      localState.isConnected = true;
    } else localState.isConnected = false;
  }, [isAVLoaded]);

  if (isRoomFull) return <RoomFull />;

  return (
    <ThemedApp
      id="themed-app"
      style={{
        background: `${
          background.filename
            ? "url(" + "/images/backgrounds/" + background.filename + "),"
            : ""
        } radial-gradient(farthest-side, rgba(0,0,0,0) 66%, rgba(0,0,0,0.03))`,
      }}
      mode={
        inTypingMode && isOnPlusPlan ? "type" : inDrawingMode ? "pen" : "auto"
      }
      isFocusedTyping={isFocusedTyping}
      Theme={theme}
      onMouseDown={() => (localState.isMouseDown = true)}
      onMouseUp={() => (localState.isMouseDown = false)}
      onKeyDown={(e) => {
        if (e.key === "p") {
          localState.inDrawingMode = !inDrawingMode;
        } else if (e.key === "t") {
          localState.inTypingMode = !localState.inTypingMode;
        } else if (e.key === "r") {
          updateStorage((storage) => {
            storage.set("reversesOn", !reversesOn);
          });
        } else if (e.key === "g") {
          localState.isGuidebookOpen = !isGuidebookOpen;
        } else if (e.key === "s") {
          updateStorage((storage) => {
            storage.set("isCardSearchOpen", !isCardSearchOpen);
          });
        } else if (e.key === "x" && window.location.hostname === "localhost") {
          localState.isOnPlusPlan = !isOnPlusPlan;
        }
      }}
      onClick={(e) => {
        if (!inTypingMode) return;
        if (!isOnPlusPlan) return;
        if (isFocusedTyping) {
          localState.isFocusedTyping = false;
          return;
        }
        const pos = [
          (e.clientX - window.innerWidth / 2 - panScreen[0]) / zoomVector[0],
          (e.clientY - window.innerHeight / 2 - panScreen[1]) / zoomVector[1],
        ] as [number, number];
        const newText: SpreadText = {
          position: pos,
          text: "",
          id: Math.random().toString(),
          owner: user?.uuid ? user.uuid : "",
        };
        updateStorage((storage) => {
          storage.set(
            "texts",
            new LiveList([...storage.get("texts"), newText])
          );
        });
      }}
      tabIndex={0}
    >
      <ImagePreloader
        filesToLoad={roomImagesToPreload}
        setIsLoaded={setAreImagesPreloaded}
      />
      <MobileMessage />
      <StyledRoomContainer
        className={"text-clickable"}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 3,
        }}
      >
        {!isAVLoaded || !areImagesPreloaded ? (
          <LoadingPage noText />
        ) : (
          <>
            <AnimatePresence>
              <SpreadTextOverlay
                key={"spread-text-overlay"}
                userId={user?.uuid ? user.uuid : ""}
              />
              {(isOnMobile || (!isOnMobile && !isInDrawState)) && (
                <Perspective
                  key="shuffle"
                  perspective={PERSPECTIVE_SHUFFLE}
                  angle={ANGLE_SHUFFLE}
                  useDefaultZoom={true}
                >
                  <Shuffle />{" "}
                </Perspective>
              )}
              <>
                <Spread />
                <Hand key="hand" />
              </>
            </AnimatePresence>
            <ActionPortalTarget />

            <RoomMenus />
            <BottomBar />
            <Cursors />
          </>
        )}

        <AV roomId={id} isAVLoaded={isAVLoaded} setIsAVLoaded={setIsAVLoaded} />
      </StyledRoomContainer>
    </ThemedApp>
  );
});

const StyledRoomContainer = React.memo(
  styled(motion.div, {
    position: "fixed",
    inset: 0,
    overflow: "hidden",
  })
);
