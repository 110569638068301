import {
  animate,
  AnimationOptions,
  HTMLMotionProps,
  motion,
  MotionValue,
  useMotionValue,
  useTransform,
} from "framer-motion";
import { memo, useEffect, useState } from "react";
import { CardBorderColor, RoomTheme } from "~/types/room/types";
import { localState } from "~/state/state";
import { CARD_SPREAD_SIZE, CARD_BASE_RADIUS } from "../../utils/consts";
import React from "react";
import { CardLoadingSvg } from "~/ui/room/CardFallbacks";
import { useSnapshot } from "valtio";
import { Vec2d } from "~/utils/useMousePosition";
interface Props extends HTMLMotionProps<"div"> {
  src: string;
  backSrc: string | null;
  size: [number, number];
  border: number;
  borderColor: CardBorderColor;
  theme: RoomTheme;
  position?: Vec2d | null;
  reversed?: boolean;
  shadow?: boolean;
  open?: boolean;
  fallbackColor?: string;
  foilPath?: string;
  foilTopOffset?: number;
  foilHeightOffset?: number;
}

const RADIUS = CARD_BASE_RADIUS;
const FRONT_STROKE_WIDTH = 1;
const BACK_STROKE_WIDTH = 3;
const OPEN_ROT_CONFIG: AnimationOptions<number> = {
  type: "tween",
  duration: 0.5,
  ease: "easeIn",
  delay: 0.1,
};
const OPEN_LIFT_CONFIG: AnimationOptions<number> = {
  type: "tween",
  duration: 0.9,
  ease: "easeOut",
};
const LIFT_TIME = 0.9;
const FLIP_TIME = 0.45;
const FLIP_DELAY = 0.1;

export const Card = React.memo(
  ({
    src,
    backSrc,
    size,
    border,
    borderColor,
    open,
    reversed,
    shadow,
    theme,
    position,
    fallbackColor,
    foilPath,
    foilTopOffset,
    foilHeightOffset,
    ...props
  }: Props) => {
    const W = size[0];
    const H = size[1];
    const rotateY = useMotionValue(180);
    const lift = useMotionValue(0);
    const [isOpen, setIsOpen] = useState(false);
    const [currentFoil, setCurrentFoil] = useState(
      backSrc?.split(".jpg").join("-Foil.png")
    );
    const [currentSrc, setCurrentSrc] = useState<string | null>(backSrc);
    const [cardOffset, setCardOffset] = useState(0);
    const { isOnMobile } = useSnapshot(localState);

    useEffect(() => {
      setCardOffset(Math.random() * 100);
    }, []);

    // Keyframed animation for flipping open card
    useEffect(() => {
      if (open) {
        animate(rotateY, [180, 0], {
          ...OPEN_ROT_CONFIG,
          duration: FLIP_TIME,
          delay: FLIP_DELAY,
        });
        animate(lift, [1, 180, 1], {
          ...OPEN_LIFT_CONFIG,
          duration: LIFT_TIME,
        });
        window.setTimeout(() => {
          setCurrentFoil(foilPath);
          setCurrentSrc(src);
        }, (LIFT_TIME * 1000) / 2 - (FLIP_DELAY * 1000) / 2);
      } else {
        // Skip the initial mount animation it tries to run
        if (rotateY.get() !== 180) {
          animate(rotateY, [0, 180], {
            ...OPEN_ROT_CONFIG,
            duration: FLIP_TIME,
            delay: FLIP_DELAY,
          });
          animate(lift, [1, 180, 1], {
            ...OPEN_LIFT_CONFIG,
            duration: LIFT_TIME,
          });
          window.setTimeout(() => {
            setCurrentFoil(backSrc?.split(".jpg").join("-Foil.png"));
            setCurrentSrc(backSrc);
          }, (LIFT_TIME * 1000) / 2 - (FLIP_DELAY * 1000) / 2);
        }
      }
    }, [open]);

    // Toggle component visibility based on open/closed
    useEffect(() => {
      rotateY.onChange((v) => setIsOpen((v + 90) % 360 < 180));
    }, [rotateY]);

    // Merge external lift (Dragging) and internal lift (Flip open)
    const z = useMotionValue(0);
    useEffect(() => {
      lift.onChange((v) => z.set(v));
    }, [lift]);
    useEffect(() => {
      if (typeof props.style?.z === "object") {
        (props.style.z as MotionValue<number>).onChange((v) => z.set(v));
      }
    }, [props.style]);

    const shadowBlur = useTransform(z, [0, 180], ["blur(2px)", "blur(20px)"]);
    const shadowColor = useTransform(
      z,
      [0, 180],
      ["rgba(0,0,0,.5)", "rgba(0,0,0,.2)"]
    );

    const cardId = Math.random().toString();

    // Padding to center different sized cards inside of the card spread slot
    // Padding is positive or negative depending on card orientation / state
    let paddingX =
      CARD_SPREAD_SIZE[0] > W ? Math.abs(CARD_SPREAD_SIZE[0] - W) / 2 : 0;
    paddingX =
      (reversed && open) || (!reversed && !open) ? paddingX * -1 : paddingX;
    let paddingY =
      CARD_SPREAD_SIZE[1] > H ? Math.abs(CARD_SPREAD_SIZE[1] - H) / 2 : 0;
    paddingY = reversed ? paddingY * -1 : paddingY;

    const [imgSvg, setImgSvg] = useState(<svg></svg>);

    useEffect(() => {
      setImgSvg(<CardLoadingSvg />);
      const img = new Image();
      img.src = src;
      img.onload = () =>
        setImgSvg(<image xlinkHref={src} x="0" y="0" width={W} height={H} />);
    }, [src]);

    const [backSrcImg, setBackSrcImg] = useState<string | null>(null);

    useEffect(() => {
      if (!backSrc) return;
      setImgSvg(<CardLoadingSvg />);
      const img = new Image();
      img.src = backSrc;
      img.onload = () => setBackSrcImg(backSrc);
    }, [backSrc]);

    const [lightPos, setLightPos] = useState([0, 0]);

    return (
      <>
        {/* Shadow */}
        {shadow && (
          <motion.div
            style={{
              position: "absolute",
              pointerEvents: "none",
              width: W,
              height: H,
              top: Math.abs(paddingY),
              left: Math.abs(paddingX),
              background: shadowColor,
              borderRadius: RADIUS,
              filter: shadowBlur,
            }}
          />
        )}

        <motion.div
          id={"card-contents-" + src}
          className="card"
          {...props}
          style={{
            ...props.style,
            rotateY,
            z,
          }}
          onPointerMove={(e) => {
            if (!isOnMobile)
              setLightPos([e.nativeEvent.offsetX, e.nativeEvent.offsetY]);
          }}
        >
          {foilPath && (
            <Foil
              foilHeightOffset={foilHeightOffset ? foilHeightOffset : 0}
              reversed={reversed as boolean}
              foilTopOffset={foilTopOffset ? foilTopOffset : 0}
              currentFoil={currentFoil as string}
              currentSrc={currentSrc as string}
              position={position ? position : [0, 0]}
              cardOffset={cardOffset}
              isOnMobile={isOnMobile}
              lightPos={lightPos}
            />
          )}
          <svg viewBox={`0 0 ${W} ${H}`} overflow="visible">
            <defs>
              <pattern
                id={`front-${cardId}`}
                patternUnits="userSpaceOnUse"
                width={W + 2 * border}
                height={H + 2 * border}
                x={paddingX - border}
                y={paddingY - border}
                viewBox={`0 0 ${W} ${H}`}
                preserveAspectRatio="xMidYMid slice"
              >
                {imgSvg}
              </pattern>
              {backSrcImg && (
                <pattern
                  id={`back-${cardId}`}
                  patternUnits="userSpaceOnUse"
                  width={W + 2 * border}
                  height={H + 2 * border}
                  x={paddingX - border}
                  y={paddingY - border}
                  viewBox={`0 0 ${W} ${H}`}
                  preserveAspectRatio="xMidYMid slice"
                >
                  <image
                    xlinkHref={backSrcImg}
                    x="0"
                    y="0"
                    width={W}
                    height={H}
                  />
                </pattern>
              )}
            </defs>
            <g style={{ opacity: isOpen ? 1 : 0 }}>
              <rect
                x={paddingX}
                y={paddingY}
                width={W}
                height={H}
                rx={RADIUS}
                fill={`url(#front-${cardId})`}
                stroke={"rgba(0,0,0,0.25)"}
                strokeWidth={FRONT_STROKE_WIDTH}
              />
            </g>
            {backSrcImg ? (
              <g style={{ opacity: isOpen ? 0 : 1 }}>
                <rect
                  x={paddingX}
                  y={paddingY}
                  width={W}
                  height={H}
                  rx={RADIUS}
                  fill={`url(#back-${cardId})`}
                  stroke={
                    theme === "dark" ? borderColor.dark : borderColor.light
                  }
                  strokeWidth={BACK_STROKE_WIDTH}
                />
              </g>
            ) : (
              <g style={{ opacity: isOpen ? 0 : 1 }}>
                <rect
                  x={paddingX}
                  y={paddingY}
                  width="100%"
                  height="100%"
                  rx={RADIUS}
                  fill={
                    fallbackColor
                      ? fallbackColor
                      : theme === "dark"
                      ? "black"
                      : "white"
                  }
                  stroke={
                    theme === "dark" ? borderColor.dark : borderColor.light
                  }
                  strokeWidth={BACK_STROKE_WIDTH}
                />
                {!backSrc && (
                  <image
                    href={
                      theme === "dark"
                        ? "/images/room/longburst-white.png"
                        : "/images/room/longburst.png"
                    }
                    x={paddingX}
                    y={paddingY}
                    width="100%"
                    height="100%"
                  />
                )}
              </g>
            )}
          </svg>
        </motion.div>
      </>
    );
  }
);

const Foil = memo(
  ({
    foilHeightOffset,
    reversed,
    foilTopOffset,
    currentFoil,
    currentSrc,
    position,
    cardOffset,
    isOnMobile,
    lightPos,
  }: {
    foilHeightOffset: number;
    reversed: boolean;
    foilTopOffset: number;
    currentFoil: string;
    currentSrc: string;
    position: Vec2d;
    cardOffset: number;
    isOnMobile: boolean;
    lightPos: number[];
  }) => {
    return (
      <div
        style={{
          width: "100%",
          height: `calc(100% - ${foilHeightOffset ? foilHeightOffset : 0}px)`,
          position: "absolute",
          top: reversed && foilTopOffset ? -foilTopOffset : foilTopOffset,
          pointerEvents: "none",
          WebkitMaskImage: `url(${currentFoil})`,
          backgroundSize: "100% 100%",
          WebkitMaskSize: "100% 100%",
          backgroundImage: `url(${currentSrc})`,
          borderRadius: "31px",
        }}
      >
        <div
          className="light-gradient"
          style={{
            width: "200%",
            height: "200%",
            pointerEvents: "none",
            position: "absolute",
            background:
              "linear-gradient(90deg, rgba(142, 255, 143, 0.5) 0%, rgba(255, 189, 79, 0.5) 20%, rgba(243, 84, 84, 0.5) 47%, rgba(83, 148, 255, 0.5) 80%, rgba(142, 255, 143, 0.5) 100%) 330px 0px",
            top: 0,
            left: 0,
            mixBlendMode: "hard-light",
            backgroundPosition: `${
              position ? cardOffset + position[0] : cardOffset
            }px 0`,
            transition: "opacity 0.2s",
          }}
        ></div>
        {!isOnMobile && (
          <div
            className="light-source"
            style={{
              width: "200px",
              height: "200px",
              backgroundColor: "white",
              borderRadius: "50%",
              position: "absolute",
              zIndex: "30",
              filter: "blur(50px)",
              pointerEvents: "none",
              transform: `translate(${lightPos[0] - 100}px, ${
                lightPos[1] - 100
              }px) `,
            }}
          ></div>
        )}
      </div>
    );
  }
);
