import { Room } from "@liveblocks/client";
import { ChangeEvent, useEffect, useState } from "react";
import { DISPLAY_NAME_CHAR_LIMIT, URL_VALIDATION_REGEX } from "~/utils/consts";
import { RoomTheme } from "~/types/room/types";
import { styled } from "~/ui/style/stitches.config";

interface MoreInfo {
  title: string;
  text: string;
}

export const BottomTextContent = ({
  recommendedBookmarks,
  moreInfo,
  theme,
}: {
  recommendedBookmarks: string[];
  moreInfo: MoreInfo[];
  theme: RoomTheme;
}) => {
  return (
    <StyledBottomTextContent>
      <div className="more-info-container">
        <div className="section more-info">
          {moreInfo.map((item, i) => (
            <div key={i}>
              <div className="title">{item.title}</div>
              <div className="text paragraph">{item.text}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="recommended-bookmarks-container">
        <div className="section recommended-bookmarks">
          {recommendedBookmarks.length > 0 && (
            <div className="title">Recommended links</div>
          )}
          {recommendedBookmarks.map((link, i) => {
            const displayLink = link
              .split("http://")
              .join("")
              .split("https://")
              .join("");
            const clickableLink = "https://" + displayLink;
            return (
              <div
                className="text bookmark"
                onClick={() => window.open(clickableLink, "_blank")}
                key={i}
              >
                <img
                  className="link-icon"
                  src={`/images/room/link-out.svg`}
                  style={{
                    filter: theme === "dark" ? "invert(1)" : "invert(0)",
                  }}
                ></img>
                <span className="link-text">{link}</span>
              </div>
            );
          })}
        </div>
      </div>
    </StyledBottomTextContent>
  );
};

const StyledBottomTextContent = styled("div", {
  display: "flex",
  width: "66.66%",
  textAlign: "left",
  marginTop: "20px",
  "@notsmaller": {
    marginTop: "40px",
  },

  "& .recommended-bookmarks-container, & .more-info-container": {
    width: "50%",
    display: "flex",
    justifyContent: "center",
  },
  "& .section": {
    "&.recommended-bookmarks": {
      width: "80%",
      maxWidth: "250px",
    },

    "&.more-info": {
      width: "100%",
      marginLeft: "-18px",
    },
    "& .title": {
      fontSize: "28px",
      // fontWeight: "600",
      marginBottom: "12px",
      fontFamily: "KeplerLightCondensed",
    },
    "& .text": {
      fontSize: "13px",
      marginTop: "10px",
      lineHeight: "1.3",
      "&.paragraph": {
        whiteSpace: "pre-wrap",
        marginBottom: "56px",
      },
      "&.bookmark": {
        display: "flex",
        cursor: "pointer",

        "& .link-text": {
          maxWidth: "100%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          display: "block",
        },

        "&:hover": {
          textDecoration: "underline",
        },
        "& .link-icon": {
          width: "20px",
          marginRight: "4px",
          marginTop: "-1px",
        },
      },
    },
  },
  "@smaller": {
    width: "80%",
    display: "flex",
    flexDirection: "column",

    "& .text": {
      fontSize: "14px",
    },
    "& .title": {
      fontSize: "18px",
    },
    "& .recommended-bookmarks-container, & .more-info-container": {
      width: "100%",
      justifyContent: "left",
    },
    "& .more-info": {
      marginLeft: "0px !important",
    },
  },
});

export const TextBoxAdder = ({
  title,
  sections,
  addSection,
  theme,
}: {
  title: string;
  sections: JSX.Element[];
  addSection: Function;
  theme: RoomTheme;
}) => {
  return (
    <StyledTextBoxAdder>
      <div className="input-headline">{title}</div>
      {sections}
      <StyledAddButton
        Theme={theme}
        onClick={(e) => {
          addSection();
          e.preventDefault();
        }}
      >
        +
      </StyledAddButton>
    </StyledTextBoxAdder>
  );
};

const StyledTextBoxAdder = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .input-headline": {
    width: "100%",
    textAlign: "left",
  },
});
const StyledAddButton = styled("div", {
  cursor: "pointer",
  width: "30px",
  marginTop: "4px",
  height: "30px",
  borderRadius: "50%",
  fontSize: "22px",
  fontWeight: "200",
  display: "flex",
  backgroundColor: "black",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  transition: "opacity 0.2s ease-in-out",
  "&:hover": {
    opacity: 0.7,
  },
  padding: 0,
  variants: {
    Theme: {
      light: {},
      dark: {
        backgroundColor: "white",
        color: "black",
      },
    },
  },
});

function isValidUrl(url: string) {
  return /^[^.]+\.[^.]+$/.test(url);
}

export const RecommendedBookmarks = ({
  theme,
  setRecommendedBookmarks,
  isSubmitting,
  recommendedBookmarks,
}: {
  theme: RoomTheme;
  setRecommendedBookmarks: Function;
  isSubmitting: boolean;
  recommendedBookmarks: string[];
}) => {
  const [sections, setSections] = useState<JSX.Element[]>([]);

  const saveBookmarks = () => {
    const recdBookmarks = [];
    const inputs = document
      .getElementById("recommended-links")!
      .querySelectorAll("input");
    for (let i = 0; i < inputs.length; i++) {
      const element = inputs[i] as HTMLInputElement;
      const value = element.value;
      if (value) recdBookmarks.push(value);
    }
    setRecommendedBookmarks(recdBookmarks);
  };

  const addSection = () => {
    const newSection = createSection();
    setSections([...sections, newSection]);
  };

  const id = Math.random().toString();

  const createSection = (link?: string) => {
    return (
      <div
        id={id}
        className="input-container"
        style={{ width: "100%" }}
        key={id}
      >
        <input
          id={id + "-input"}
          className="input-link editable"
          type="text"
          spellCheck={false}
          name={`section-${sections.length}`}
          placeholder="recommendedlink.com"
          onInvalid={(e) => {
            e.preventDefault();
          }}
          defaultValue={link ? link : ""}
          pattern={URL_VALIDATION_REGEX}
          onChange={() => {
            saveBookmarks();
          }}
        />
        <p className="error-message">Please enter a valid link</p>
      </div>
    );
  };

  useEffect(() => {
    if (recommendedBookmarks.length === 0) addSection();
    else {
      const newSections: JSX.Element[] = [];
      recommendedBookmarks.forEach((link) => {
        const newSection = createSection(link);
        newSections.push(newSection);
      });
      setSections(newSections);
    }
  }, []);

  return (
    <StyledBookMarkAdder id="recommended-links" Theme={theme}>
      <TextBoxAdder
        title={"Recommended links"}
        sections={sections}
        addSection={addSection}
        theme={theme}
      ></TextBoxAdder>
    </StyledBookMarkAdder>
  );
};

const StyledBookMarkAdder = styled("div", {
  "& input": {
    paddingLeft: "36px !important",
    backgroundPosition: "10px calc(50%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "20px",
    backgroundImage: "url(/images/room/link-out.svg)",
    "&.editable::placeholder": {
      opacity: 0.6,
    },
  },
  variants: {
    Theme: {
      light: {},
      dark: {
        "& input": {
          backgroundImage: "url(/images/room/link-out-dark.svg)",
        },
      },
    },
  },
});

// decode encoded characters
export function decodeHtmlEntities(text: string) {
  const entities = [
    ["amp", "&"],
    ["apos", "'"],
    ["lt", "<"],
    ["gt", ">"],
    ["quot", '"'],
    ["nbsp", " "],
  ];

  return entities.reduce((acc, [entity, char]) => {
    const regex = new RegExp(`&${entity};`, "g");
    return acc.replace(regex, char).replace(/<br>/g, "\n");
  }, text);
}

export const MoreInfo = ({
  theme,
  setMoreInfo,
  isSubmitting,
  moreInfo,
}: {
  theme: RoomTheme;
  setMoreInfo: Function;
  isSubmitting: boolean;
  moreInfo: MoreInfo[];
}) => {
  const starterSections = [
    {
      title: "About My Practice",
      placeholderText: "My approach to Tarot is...",
    },
    {
      title: "Favorite Decks",
      placeholderText: "The decks I connect with the most are...",
    },
    {
      title: "Remarks & Reflections",
      placeholderText: `"Incredible conversations!" - Fan
"Deeply fulfilling and so much fun." - Client
      `,
    },
    {
      title: "Values & Guidelines",
      placeholderText:
        "The principles that guide my practice are kindness, curiosity, and respect. I expect...",
    },
  ];
  const [sections, setSections] = useState<JSX.Element[]>([]);

  const saveMoreInfo = () => {
    const mrInfo = [];

    const inputs = document
      .getElementById("more-info-inputs")!
      .querySelectorAll(".more-info-section");

    for (let i = 0; i < inputs.length; i++) {
      const element = inputs[i];
      const h1Value = decodeHtmlEntities(element.children[0].innerHTML);
      const pValue = decodeHtmlEntities(element.children[1].innerHTML);
      if (pValue)
        mrInfo.push({
          title: h1Value,
          text: pValue,
        });
    }
    setMoreInfo(mrInfo);
  };

  const createSection = ({
    title,
    text,
    placeholderText,
  }: {
    title?: string;
    text?: string;
    placeholderText?: string;
  }) => {
    const id = Math.random().toString();
    return (
      <div
        key={id}
        className="textarea more-info-section"
        id={id}
        onKeyUp={(e) => {
          saveMoreInfo();
        }}
      >
        <>
          <h1
            className="editable"
            defaultValue="Heading"
            suppressContentEditableWarning={true}
            //@ts-ignore
            contentEditable="plaintext-only"
            spellCheck={true}
          >
            {title ? title : ""}
          </h1>
          <p
            className="editable"
            defaultValue={placeholderText ? placeholderText : "Text"}
            //@ts-ignore
            contentEditable="plaintext-only"
            suppressContentEditableWarning={true}
            spellCheck={true}
          >
            {text}
          </p>
        </>
      </div>
    );
  };

  const addSection = () => {
    const newSection = createSection({ title: "", text: "" });
    setSections([...sections, newSection]);
  };

  useEffect(() => {
    const newStarterSections = [...starterSections];
    const newSections: JSX.Element[] = [];
    moreInfo.forEach((info) => {
      newStarterSections.forEach((starterSection) => {
        if (info.title === starterSection.title)
          newStarterSections.splice(
            newStarterSections.indexOf(starterSection),
            1
          );
      });
      const newSection = createSection(info);
      newSections.push(newSection);
    });

    const starterSectionEls = [];
    for (let i = 0; i < newStarterSections.length; i++) {
      const section = createSection(newStarterSections[i]);
      starterSectionEls.push(section);
    }
    setSections([...newSections, ...starterSectionEls]);
    // }
  }, []);

  return (
    <StyledMoreInfo id="more-info-inputs">
      <TextBoxAdder
        title={"More info"}
        sections={sections}
        addSection={addSection}
        theme={theme}
      ></TextBoxAdder>
    </StyledMoreInfo>
  );
};

const StyledMoreInfo = styled("div", {
  "& .textarea": {
    padding: "24px 18px",
    marginBottom: "6px",
    "& .editable:empty:before": {
      content: "attr(placeholder)",
      opacity: "0.4",
    },
    "& .editable": {
      cursor: "text",
      outline: "none !important",
      whiteSpace: "pre-wrap",
    },

    height: "auto",
    minHeight: "150px",
    textAlign: "left",
    "& h1": {
      fontSize: "28px",
      fontFamily: "KeplerLightCondensed",
      opacity: "0.9",
    },
    "& p": {
      fontSize: "13px",
      lineHeight: "1.3",
      marginTop: "12px",
      minHeight: "100px",
    },
  },
});
