import { RoomState } from "~/types/room/types";
import { localState } from "~/state/state";

import { Guidebook } from "../room/Guidebook";
import { BottomBar } from "./BottomBar";
import { SpreadNav } from "./SpreadNav";
import { Toolbar } from "./Toolbar";
import { useStorage } from "~/state/liveblocks.config";
import { useSnapshot } from "valtio";
import { HelpButtonSmall } from "../components/HelpButton";

export const RoomMenus = () => {
  const state = useStorage((root) => root.state);
  const { isGuidebookOpen, isOnMobile, lastViewedCard } =
    useSnapshot(localState);
  return (
    <div
      style={{ WebkitUserSelect: "none" }}
      onClick={(e) => e.stopPropagation()}
    >
      <Toolbar />
      <Guidebook />

      {state === RoomState.Draw && <SpreadNav />}
      <BottomBar />
    </div>
  );
};
